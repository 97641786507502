<template>
  <div>
    <datatable :data="referredAffiliates.data" :columns="referredAffiliates.columns" />
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: {
    staff: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      referredAffiliates: this.$options.resource([], {
        query: "",
        columns: [
          {
            name: "affiliate",
            th: "Name",
            render: (referred) => {
              if (!referred.affiliate) {
                return "---";
              }
              return `${referred.affiliate.first_name} ${referred.affiliate.last_name}`;
            },
          },
          {
            name: "confirmed_date",
            th: "Confirmed Date",
            render: (referred) => {
              if (!referred.created_date) {
                return "---";
              }
              return moment(referred.created_date).format(
                "MMM Do, YYYY h:mm:ss a"
              );
            },
          },
        ],
        loading: false,
      }),
    };
  },
  computed: {},
  beforeMount() {
    this.affiliates()
  },
  methods: {
    async affiliates() {
      this.referredAffiliates.loading = true;

      await this.$get({
        url: `${this.$baseurl}/admin/affiliates/${this.staff.id}/originator/referrals`,
        headers: this.headers,
        success: (response) => {
          this.referredAffiliates.data = response.data.data.referred_affiliate
        },
        error: (error) => {
          console.log("err", error);
        },
      });

      this.referredAffiliates.loading = false;
    },
  },
};
</script>
