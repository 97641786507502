<template>
	<div>

		<div class="grid grid-cols-1 md:grid-cols-2 mb-4">
			<div class="col-span-1 flex items-center flex-wrap">
				<h2 class="text-2xl font-bold">All Employees</h2>
				<router-link :to="{ name: 'staff-new'}" class="text-sm text-gray-700 md:ml-3">+ Add new staff</router-link>
			</div>
			<div class="col-span-1">
				<div class="relative">
					<input
						type="search"
						name="search"
						class="border border-solid border-blue-200 rounded placeholder-blue-200 outline-none pl-10 pr-2 py-2 text-xs w-full"
						placeholder="Search for employee’s name, status, etc."
						v-model="searchQuery">
					<ion-icon name="search-outline" class="text-blue-200 absolute z-10 text-lg ml-3 left-0" style="top: 50%; transform: translateY(-50%);"></ion-icon>
				</div>
			</div>
		</div>

		<div class="border border-solid border-blue-200">
			<div class="flex">
				<div class="duration-300" :class="{'w-full': !isOpen, 'w-1/2': isOpen}">
					<datatable
						:data="employees.data"
						:columns="columns"
						:onClick="click"
						:query="searchQuery"
						:className="tableRowClassName"
						:loading="employees.loading"
						ref="table"
					/>
				</div>
				<div class="w-1/2 px-8 py-10 border-l border-blue-200" v-if="isOpen">
					<div class="h-full overflow-y-auto">
						
						<div class="animated animation-delay-300 fadeIn">
							<div class="flex flex-row items-center justify-between mb-5">
								<div class="flex flex-row items-baseline">
									<h2 class="text-lg font-bold mr-2">{{ employee.name | sentenceCase }} {{ employee.last_name | sentenceCase }}</h2>
									<span class="font-hairline text-xs text-gray-500">{{ position | sentenceCase }}</span>
								</div>
								<!-- <div class="dropdown ml-auto">
									<button type="button" class="flex flex-col focus:outline-none" dropdown>
										<div class="h-1 w-1 bg-gray-400 mb-1 rounded-full"></div>
										<div class="h-1 w-1 bg-gray-400 mb-1 rounded-full"></div>
										<div class="h-1 w-1 bg-gray-400 rounded-full"></div>
									</button>
									<ul class="dropdown-menu">
										<a href="#!" class="dropdown-menu-item text-red-500" @click.prevent="confirmDelete">Delete</a>
									</ul>
								</div> -->
							</div>


							<transition name="fade" mode="out-in" v-if="0">
								<div class="h-100px" v-if="employeeApprovalLoading">
									<sm-loader />
								</div>

								<div v-else>
										
									<!-- Profile Not Completed -->
									<div class="mb-10" v-if="!employeeProfileCompleted">

										<div class="rounded-full bg-blue-100 p-3 flex flex-row items-center mb-5">
											<div class="flex justify-center items-center bg-blue-500 text-white rounded-full w-20px h-20px text-xs text-center mr-3">
												!
											</div>
											<div class="text-xs font-normal">
												{{ employeeFirstName }} hasn't completed their profile.
											</div>
										</div>

									</div>
									<!-- Profile Not Completed -->

									<!-- Profile Completed -->
									<template v-else>
										<div class="text-xs">
											<template v-if="employeeApprovalCompleted">

												<div class="rounded-full bg-green-100 p-3 flex flex-row items-center mb-5">
													<div class="flex justify-center items-center bg-green-500 text-white rounded-full w-20px h-20px text-xs text-center mr-3">
														!
													</div>
													<div class="text-xs font-normal">
														<template v-if="employeeApproval && employeeApproval.action == 'activate'">
															Profile activated successfully.
														</template>
														<template v-else>
															Profile deactivated successfully.
														</template>
													</div>
												</div>
												
											</template>

											<template v-else-if="employeeApprovalPending">
													
												<div class="rounded-full bg-blue-100 p-3 flex flex-row items-center mb-5">
													<div class="flex justify-center items-center bg-blue-500 text-white rounded-full w-20px h-20px text-xs text-center mr-3">
														!
													</div>
													<div class="text-xs font-normal">
														{{ employeeFirstName }}'s profile needs activation.
													</div>
												</div>

											</template>

										</div>

										<div class="mb-10">

											<div
												class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-4 mb-2"
												v-if="!employeeApprovalLoading"
											>
												<div class="col-span-1">
													<div class="flex justify-start items-center">
														<button
															class="button bg-blue-500 hover:bg-blue-500 text-white"
															@click.prevent="activateProfile"
															v-if="!employeeProfileActivated"
														>
															Activate Profile
														</button>
													</div>
												</div>
												<div class="col-span-1">
													<div class="flex justify-start md:justify-end lg:justify-start xl:justify-end items-center">
														<button
															class="button border border-red-500 hover:bg-red-500 text-red-500 hover:text-white"
															@click.prevent="deactivateProfile"
															v-if="!employeeProfileDeactivated"
														>
															Deactivate Profile
														</button>
													</div>
												</div>
											</div>

											<div class="text-red-500 text-xs text-right" v-if="employeeApprovalError">
												{{ employeeApprovalError }}
											</div>
										</div>

									</template>
									<!-- Profile Completed -->

								</div>

							</transition>

							<div class="grid grid-cols-1 xl:grid-cols-2 border-blue-200 border mb-8">

								<div class="col-span-1">
									<div class="px-8 xl:px-12 py-8 border-blue-200 relative">
										<!-- <span class="absolute h-4 bg-green-500 block left-0 ml-3 xl:ml-5" style="width: 3px"></span> -->
										<div class="flex justify-between items-center mb-4">
											<div class="text-xs">Salary</div>
											<div class="flex items-center justify-center w-6 h-6 border-2 border-solid border-green-500 text-green-500 rounded-full font-black">
												₦
											</div>
										</div>
										<div class="text-xs font-medium">₦ {{ employee.profile ? employee.profile.salary : 0 | currency }}</div>
									</div>
								</div>

								<div class="col-span-1">
									<div class="px-8 xl:px-12 py-8 border-t xl:border-t-0 xl:border-l border-blue-200 relative">
										<!-- <span class="absolute h-4 bg-blue-500 block left-0 ml-3 xl:ml-5" style="width: 3px"></span> -->
										<div class="flex justify-between items-center mb-4">
											<div class="text-xs">Position</div>
											<div class="w-6 h-6 flex items-center justify-center border border-solid border-blue-500 text-blue-500 rounded-full font-black text-sm">
												<ion-icon name="briefcase-outline"></ion-icon>
											</div>
										</div>
										<div class="text-xs font-medium">{{ employee.profile ? employee.profile.position : '---' }}</div>
									</div>
								</div>

								<div class="col-span-1">
									<div class="px-8 xl:px-12 py-8 border-t border-blue-200 relative">
										<!-- <span class="absolute h-4 bg-blue-500 block left-0 ml-3 xl:ml-5" style="width: 3px"></span> -->
										<div class="flex justify-between items-center mb-4">
											<div class="text-xs">Phone Number</div>
											<div class="w-6 h-6 flex items-center justify-center border border-solid border-blue-500 text-blue-500 rounded-full font-black text-sm">
												<ion-icon name="call-outline"></ion-icon>
											</div>
										</div>
										<div class="text-xs font-medium">{{ employee.profile ? employee.profile.phone_no : '---' }}</div>
									</div>
								</div>

								<div class="col-span-1">
									<div class="px-8 xl:px-12 py-8 border-t xl:border-l border-blue-200 relative">
										<!-- <span class="absolute h-4 bg-blue-500 block left-0 ml-3 xl:ml-5" style="width: 3px"></span> -->
										<div class="flex justify-between items-center mb-4">
											<div class="text-xs">Email</div>
											<div class="w-6 h-6 flex items-center justify-center border border-solid border-blue-500 text-blue-500 rounded-full font-black text-sm">
												<ion-icon name="mail-outline"></ion-icon>
											</div>
										</div>
										<div class="text-xs font-medium">{{ employee.email }}</div>
									</div>
								</div>

								<div class="col-span-1">
									<div class="px-8 xl:px-12 py-8 border-t border-blue-200 relative">
										<!-- <span class="absolute h-4 bg-blue-500 block left-0 ml-3 xl:ml-5" style="width: 3px"></span> -->
										<div class="flex justify-between items-center mb-4">
											<div class="text-xs">Employment Type</div>
											<div class="w-6 h-6 flex items-center justify-center border border-solid border-blue-500 text-blue-500 rounded-full font-black text-sm">
												<ion-icon name="briefcase-outline"></ion-icon>
											</div>
										</div>
										<div class="text-xs font-medium">{{ employee.profile ? employee.profile.emp_type : '---' }}</div>
									</div>
								</div>

								<div class="col-span-1">
									<div class="px-8 xl:px-12 py-8 border-t xl:border-l border-blue-200 relative">
										<!-- <span class="absolute h-4 bg-blue-500 block left-0 ml-3 xl:ml-5" style="width: 3px"></span> -->
										<div class="flex justify-between items-center mb-4">
											<div class="text-xs">Loan Facility</div>
											<div class="w-6 h-6 flex items-center justify-center border border-solid border-blue-500 text-blue-500 rounded-full font-black text-sm">
												<ion-icon name="calculator-outline"></ion-icon>
											</div>
										</div>
										<div class="text-xs font-medium">{{ employee.profile ? employee.profile.current_loan_facility : '---' }}</div>
									</div>
								</div>

								<div class="col-span-1">
									<div class="px-8 xl:px-12 py-8 border-t border-blue-200 relative">
										<!-- <span class="absolute h-4 bg-green-500 block left-0 ml-3 xl:ml-5" style="width: 3px"></span> -->
										<div class="flex justify-between items-center mb-4">
											<div class="text-xs">Total Loan Amount</div>
											<div class="flex items-center justify-center w-6 h-6 border-2 border-solid border-green-500 text-green-500 rounded-full font-black">
												₦
											</div>
										</div>
										<div class="text-xs font-medium">₦ {{ totalLoanAmount | currency }}</div>
									</div>
								</div>

								<div class="col-span-1">
									<div class="px-8 xl:px-12 py-8 border-t xl:border-l border-blue-200 relative">
										<!-- <span class="absolute h-4 bg-orange-500 block left-0 ml-3 xl:ml-5" style="width: 3px"></span> -->
										<div class="flex justify-between items-center mb-4">
											<div class="text-xs">Current Loan</div>
											<div class="flex items-center justify-center w-6 h-6 border-2 border-solid border-green-500 text-green-500 rounded-full font-black">
												₦
											</div>
										</div>
										<div class="text-xs font-medium">₦ 0.00</div>
									</div>
								</div>

							</div>

							<h2 class="text-lg font-bold mb-5">Recent Transactions</h2>

							<div class="border border-solid border-blue-200">
								<datatable :data="transactions" :columns="transactionColumns" />
							</div>

						</div>
						
					</div>
				</div>
			</div>
		</div>

		<modal className="w-full md:w-2/5 xl:w-3/10 text-center" ref="confirmDeleteModal">

			<div class="mb-10">
				<div class="text-xl font-bold mb-10">
					Delete This User?
				</div>

				<div class="font-light text-gray-500 text-sm">
					<p class="mb-2">This user will be permanently removed from the platform.</p>
				</div>
			</div>

			<div class="flex items-center justify-center">

				<button
					type="button"
					class="button bg-blue-500 hover:bg-blue-600 text-white"
					@click.prevent="$refs.confirmDeleteModal.close"
					:disabled="deleting"
				>
					Cancel
				</button>
				

				<button
					type="button"
					class="button bg-red-500 hover:bg-red-600 text-white ml-3"
					@click.prevent="deleteEmployee"
					:disabled="deleting"
				>
					<span v-if="deleting">Deleting...</span>
					<span v-else>Proceed</span>
				</button>
			</div>

		</modal>

		<modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="deleteModal">

			<img :src="assets.icons.lg.checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

			<div class="text-lg font-bold mb-4">
				Delete!
			</div>
			<div class="text-xs mb-10">
				Employee Deleted Successfully.
			</div>

			<button type="button" class="button bg-blue-500 hover:bg-blue-600 text-white" @click.prevent="$refs.deleteModal.close">
				Okay
			</button>
			
		</modal>

	</div>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		data() {
			return {
				columns: [
					{
						name: 'name',
						th: 'Staff Name',
						render: (staff) => staff.name + ' ' + (staff.last_name || '')
					},
					{
						name: 'email',
						th: 'Email Address',
					},
					{
						name: 'status',
						th: 'Profile Approval',
						render: employee => {
							if (!employee.profile) {
								return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-orange-100 text-xs px-3 py-2 inline-block">
									Not Updated
								</div>`;
							}

							switch (employee.profile.status) {
								case 'activated':
									return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-3 py-2 inline-block">
										Activated
									</div>`;
								case 'deactivated':
									return `<div class="rounded-sm border border-red-500 text-red-500 bg-red-100 text-xs px-3 py-2 inline-block">
										Deactivated
									</div>`;
								case 'pending':
								default:
									return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-orange-100 text-xs px-3 py-2 inline-block">
										Pending
									</div>`;
							}
						}
					},
					{
						name: 'loans',
						th: 'Total Loans',
						render: employee => employee.loan?.length
					},
					{
						name: 'bank_account_number',
						th: 'Bank Account Number',
						render: (employee) => employee.profile?.account_no || '---'
					},
					{
						name: 'bank_name',
						th: 'Bank Name',
						render: (employee) => this.$options.filters.bankName(employee.profile?.bank_name, this.banks)
					}
				],
				deleting: false,
				employee: null,
				loading: null,
				profileApprovals: [],
				searchQuery: '',
				transactionColumns: [
					{
						name: 'created_at',
						th: 'Time',
						render: (transaction, created_at) => {
							return this.$options.filters.dateFormat(created_at, 'M dd')+' at '+this.$options.filters.dateFormat(created_at, 'h:ia')
						}
					},
					{
						name: 'status',
						th: 'Status',
						render: (transaction, status) => {
							switch (status) {
								case 'approved':
									return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-3 py-2 inline-block">
										Confirmed
									</div>`;
								case 'declined':
									return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-orange-100 text-xs px-3 py-2 inline-block">
										Pending
									</div>`;
								case 'paid':
									return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-3 py-2 inline-block">
										Confirmed
									</div>`;
								case 'pending':
								default:
									return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-orange-100 text-xs px-3 py-2 inline-block">
										Pending
									</div>`;
							}
						}
					},
					{
						align: 'right',
						name: 'loan_amount',
						th: 'Amount',
						render: (transaction, amount) => `₦ ${this.$options.filters.currency(amount)}`
					},
				]
			}
		},
		computed: {
			...mapState(['banks']),
			employees() {
				return this.resources.staff;
			},
			
			company_id() {
				return this.user.company_id;
			},
			company_name() {
				return this.$route.query.company_name;
			},
			employeeFirstName() {
				return this.employee?.name.split(' ')[0];
			},
			employeeProfileActivated() {
				return (!!this.employeeApprovalCompleted 
					&& this.employeeApproval.action === 'activate')
					|| this.employee?.profile?.status === 'activated';
			},
			employeeProfileCompleted() {
				return this.employee?.profile;
			},
			employeeProfileDeactivated() {
				return (!!this.employeeApprovalCompleted 
					&& this.employeeApproval.action === 'deactivate')
					|| this.employee?.profile?.status === 'deactivated';
			},
			employeeApprovalPending() {
				return this.employee?.profile?.status === 'pending';
			},
			employeeApproval() {
				return this.profileApprovals.find( approval => approval.id === this.employee.id );
			},
			employeeApprovalCompleted() {
				return this.employeeApproval?.response?.data?.status;
			},
			employeeApprovalError() {
				const error = this.employeeApproval?.error;
				if (!error) {
					return null;
				}

				if (error?.toString().match(/Error: Network Error/i)) {
					return 'Please check your internet connection';
				}

				if (error?.response?.data?.message) {
					return error?.response?.data?.message;
				}

				return 'Please try again later.';

			},
			employeeApprovalLoading() {
				return this.employeeApproval?.loading === true;
			},
			isOpen() {
				return !!this.employee
			},
			position() {
				return this.employee?.profile?.position;
			},
			totalLoanAmount() {
				return this.transactions && this.transactions.reduce( (last, transaction) => last + Number(transaction.loan_amount), 0 );
			},
			transactions() {
				return this.employee?.loan;
			},
		},
		beforeMount() {
			this.getStaff(false);
		},
		methods: {
			async activateProfile() {

				const employee = this.employee;

				this.profileApprovals = this.profileApprovals.filter(approval => approval.id != employee.id);
				this.profileApprovals.push({
					id: employee.id,
					action: 'activate',
					loading: true,
					response: null,
					error: null,
				});

				await this.$post({
					url: `${this.$baseurl}/staff/profile/activate`,
					data: {
						user_id: employee.id
					},
					headers: this.headers,
					success: response => {
						this.profileApprovals.map( approval => {
							if (approval.id === employee.id) {
								approval.loading = false;
								approval.response = response;
							}
							return approval;
						} );
						this.getStaff(true);
					},
					error: error => {
						this.profileApprovals.map( approval => {
							if (approval.id === employee.id) {
								approval.loading = false;
								approval.error = error;
							}
							return approval;
						} );
					}
				});

				// this.profileApprovals.filter( approval => approval.id != employee.id );
			},
			async deactivateProfile() {

				const employee = this.employee;

				this.profileApprovals = this.profileApprovals.filter(approval => approval.id != employee.id);
				this.profileApprovals.push({
					id: employee.id,
					action: 'deactivate',
					loading: true,
					response: null,
					error: null,
				});

				await this.$post({
					url: `${this.$baseurl}/staff/profile/deactivate`,
					data: {
						user_id: employee.id
					},
					headers: this.headers,
					success: response => {
						this.profileApprovals.map( approval => {
							if (approval.id === employee.id) {
								approval.loading = false;
								approval.response = response;
							}
							return approval;
						} );
						this.getStaff(true);
					},
					error: error => {
						this.profileApprovals.map( approval => {
							if (approval.id === employee.id) {
								approval.loading = false;
								approval.error = error;
							}
							return approval;
						} );
					}
				});

				// this.profileApprovals.filter( approval => approval.id != employee.id );
			},
			confirmDelete() {
				this.$refs.confirmDeleteModal.open();
			},
			async deleteEmployee() {
				this.deleting = true;
				await this.$_delete({
					url: `${this.$baseurl}/staff/delete/${this.employee.id}`,
					headers: this.headers,
					success: () => {
						this.$refs.confirmDeleteModal.close();
						this.$refs.deleteModal.open();
						this.getStaff(true);
					}
				});
				this.deleting = false;
			},
			click(employee) {
				this.employee = this.employee === employee ? null : employee;
				this.$refs.table.renderData();
			},
			tableRowClassName(row) {
				return row.id === this.employee?.id ? 'selected' : '';
			},
			async getStaff(forced = false) {
				this.loadResource('staff', forced);
			},
		}
	}
</script>