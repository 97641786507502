<template>
  <div>
    <div class="mb-10">
      <div class="text-lg font-bold mb-5">
        Affiliate
      </div>
      <div class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200">
        <div class="col-span-2 p-4">
          <div class="opacity-75 text-sm font-medium mb-1">Add Url</div>
          <div
            class="text-gray-500 text-sm font-light flex flex-row items-center"
          >
            <ion-icon name="globe-outline" class="text-lg mr-2"></ion-icon>
            {{ addUrl }}
          </div>
        </div>
      </div>

      <div
        class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200"
        style="margin-top: -1px"
      >
        <div class="col-span-2 p-4">
          <div class="opacity-75 text-sm font-medium mb-1">Embedded Url</div>
          <div
            class="text-gray-500 text-sm font-light flex flex-row items-center"
          >
            <ion-icon name="globe-outline" class="text-lg mr-2"></ion-icon>
            {{ bannerUrl }}
          </div>
        </div>
      </div>

      <div
        class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200"
        style="margin-top: -1px"
      >
        <div class="col-span-1 p-4">
          <div class="opacity-75 text-sm font-medium mb-1">Account No</div>
          <div
            class="text-gray-500 text-sm font-light flex flex-row items-center"
          >
            <ion-icon name="card-outline" class="text-lg mr-2"></ion-icon>

            <template v-if="bankName">
              {{ accountNumber }}
            </template>
            <template v-else>
              N/A
            </template>
          </div>
        </div>
        <div
          class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200"
        >
          <div class="opacity-75 text-sm font-medium mb-1">Bank Name</div>
          <div
            class="text-gray-500 text-sm font-light flex flex-row items-center"
          >
            <ion-icon name="business-outline" class="text-lg mr-2"></ion-icon>
            <template v-if="bankName">
              {{ bankName }}
            </template>
            <template v-else>
              N/A
            </template>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-10">
      <div class="text-lg font-bold mb-5">
        Account Stats
      </div>
      <div class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200">
        <div class="col-span-1 p-4">
          <div class="opacity-75 text-sm font-medium mb-1">Total Leads</div>
          <div
            class="text-gray-500 text-sm font-light flex flex-row items-center"
          >
            <ion-icon name="people-outline" class="text-lg mr-2"></ion-icon>
            {{ totalLeads }}
          </div>
        </div>
        <div
          class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200"
        >
          <div class="opacity-75 text-sm font-medium mb-1">
            Total Processing
          </div>
          <div
            class="text-gray-500 text-sm font-light flex flex-row items-center"
          >
            <ion-icon name="arrow-redo-circle-outline" class="text-lg mr-2"></ion-icon>
            {{ totalApproved }}
          </div>
        </div>
      </div>

      <div
        class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200"
        style="margin-top: -1px"
      >
        <div class="col-span-1 p-4">
          <div class="opacity-75 text-sm font-medium mb-1">Total Processing</div>
          <div
            class="text-gray-500 text-sm font-light flex flex-row items-center"
          >
            <ion-icon name="arrow-redo-circle-outline" class="text-lg mr-2"></ion-icon>
            {{ totalProcessing }}
          </div>
        </div>
        <div
          class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200"
        >
          <div class="opacity-75 text-sm font-medium mb-1">Total Incomplete Signup</div>
          <div
            class="text-gray-500 text-sm font-light flex flex-row items-center"
          >
            <ion-icon name="arrow-redo-circle-outline" class="text-lg mr-2"></ion-icon>
            {{ totalIncompleteSignup }}
          </div>
        </div>
      </div>
      
      <div
        class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200"
        style="margin-top: -1px"
      >
        <div class="col-span-1 p-4">
          <div class="opacity-75 text-sm font-medium mb-1">Total Disbursed</div>
          <div
            class="text-gray-500 text-sm font-light flex flex-row items-center"
          >
            <ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
            ₦ {{ totalDisbursed | currency }}
          </div>
        </div>
        <div
          class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200"
        >
          <div class="opacity-75 text-sm font-medium mb-1">Total Earned</div>
          <div
            class="text-gray-500 text-sm font-light flex flex-row items-center"
          >
            <ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
            ₦ {{ totalEarned | currency }}
          </div>
        </div>
      </div>

      <div
        class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200"
        style="margin-top: -1px"
      >
        <div class="col-span-1 p-4">
          <div class="opacity-75 text-sm font-medium mb-1">Total Current Request</div>
          <div
            class="text-gray-500 text-sm font-light flex flex-row items-center"
          >
            <ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
            ₦ {{ totalCurrentRequest | currency }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    staff: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      referralUrl: "http://credpal.com/",
      approval: this.$options.basicForm([]),
      creditHistory: null,
      creditHistoryColumns: [
        {
          name: "SubscriberName",
          th: "Lender",
        },
        {
          name: "OpeningBalanceAmt",
          th: "Amount",
          render: (loan, amount) => `₦ ${amount}`,
        },
        {
          name: "InstalmentAmount",
          th: "Instalment",
          render: (loan, amount) => `₦ ${amount}`,
        },
        {
          name: "LoanDuration",
          th: "Duration",
          render: (loan, duration) => `${duration} Months`,
        },
        {
          name: "AccountStatus",
          th: "Status",
        },
      ],
      loading: false,
      loadingSummary: false,
      userSummary: null,
    };
  },
  computed: {
    addUrl() {
      return `${this.staff?.code.social_dynamic_link}`;
    },
    bannerUrl() {
      return `${this.staff?.code.embed_dynamic_link}`;
    },
    accountNumber() {
      return this.staff.profile?.account_no;
    },
    bankName() {
      return this.staff.profile?.bank_name;
    },
    totalLeads() {
      return this.userSummary?.total_leads || 0;
    },
    totalApproved() {
      return (
        this.userSummary?.total_approved|| 0
      );
    },
    totalProcessing() {
      return (
        this.userSummary?.total_processing || 0
      );
    },
    totalIncompleteSignup() {
      return (
        this.userSummary?.total_incomplete_signup || 0
      );
    },
    totalConversions() {
      return this.$options.filters.currency(
        this.userSummary?.total_earned_rewards || 0
      );
    },
    totalDisbursed() {
      return this.$options.filters.currency(
        this.userSummary?.total_disbursed_rewards || 0
      );
    },
    totalEarned() {
      return this.$options.filters.currency(
        this.userSummary?.total_earned_rewards || 0
      );
    },
    totalCurrentRequest() {
      return this.$options.filters.currency(
        this.userSummary?.total_current_requested_rewards || 0
      );
    },
    bankStatements() {
      return this.staff?.bank_statements || [];
    },
    currentLoan() {
      return this.staff?.active_loans?.[0];
    },
    currentLoanType() {
      const loanType = this.currentLoan?.loan_type;

      switch (loanType) {
        case "standard":
          return "Standard Loan";
        case "pay_day":
          return "Pay Day Loan";
        default:
          return this.currentLoan?.loan_type || "None";
      }
    },
    currentLoanAmount() {
      return this.currentLoan?.loan_amount || 0;
    },
    lastBankStatement() {
      return this.bankStatements[this.bankStatements.length - 1];
    },
    position() {
      return this.staff?.profile ? this.staff?.profile.position : "Staff";
    },
    staffId() {
      return this.$route.params.staffId;
    },
    totalLoanAmount() {
      return (
        this.loans &&
        this.loans.reduce(
          (last, transaction) => last + Number(transaction.loan_amount),
          0
        )
      );
    },
    loans() {
      return this.staff?.loan || [];
    },
    loanHistory() {
      return this.creditHistory?.account_summary || [];
    },
    totalCreditLoans() {
      const writtenoff = this.loanCount("writtenoff");
      const open = this.loanCount("open");
      const closed = this.loanCount("closed");

      return writtenoff + open + closed;
    },
    hasStaffRole() {
      return this.staff?.roles?.find((role) => role.slug == "company_staff");
    },
  },
  mounted() {
    this.getUserSummary()
  },
  methods: {
    loanCount(key) {
      const loan_counts = this.creditHistory?.total_summary?.loan_count;
      return loan_counts && loan_counts[key] ? loan_counts[key] : 0;
    },
    profileData(key, default_value = "N/A") {
      const data = this.staff?.profile?.[key];
      return data ? data : default_value;
    },
    async getUserSummary() {
      this.loadingSummary = true;
      await this.$get({
        url: `${this.$baseurl}/admin/affiliates/${this.staff.id}/rewards/summary`,
        headers: this.headers,
        success: (response) => {
          this.userSummary = response.data.data;
        },
        error: (error) => {
          console.log("err", error);
        },
      });

      this.loadingSummary = false;
    },
  },
};
</script>
