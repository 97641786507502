<template>
  <div>
    <datatable :data="referredCustomers" :columns="columns" />
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: {
    staff: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      columns: [
        {
          name: "full_name",
          th: "Name",
          render: (user) => {
            if (!user.owner) {
              return "---";
            }
            return `${user.owner.name} ${user.owner.last_name}`;
          },
        },
        {
          name: "confirmed_date",
          th: "Confirmed Date",
          render: (user) => {
            if (!user.confirmed_date) {
              return "---";
            }
            return moment(user.confirmed_date).format("MMM Do, YYYY h:mm:ss a");
          },
        },
        // {
        //   name: "amount",
        //   th: "Amount",
        //   render: (user) => {
        //     return this.$options.filters.currency(user.reward?.commission || 0);
        //   },
        // },
        {
          name: "status",
          th: "Conversion Status",
          render: (user) => {
            switch (user.status) {
              case "approved":
                return `<div class="badge badge-green-soft-outline">
											Approved
										</div>`;
              case "rejected":
                return `<div class="badge badge-red-soft-outline">
											Rejected
                    </div>`;
              case "incomplete-signup":
                return `<div class="badge badge-orange-soft-outline">
											Incomplete Signup
										</div>`;
              case "processing":
                return `<div class="badge badge-orange-soft-outline">
											Processing
										</div>`;
              case "pending":
              default:
                return `<div class="badge badge-orange-soft-outline">
											Pending
										</div>`;
            }
          },
        },
        {
          name: "created_date",
          th: "Register Date",
          render: (user) => {
            if (!user.created_date) {
              return "---";
            }
            return moment(user.created_date).format("MMM Do, YYYY h:mm:ss a");
          },
        },
      ],
    };
  },
  computed: {
    referredCustomers() {
      return this.staff.referred_customers || [];
    },
  },
  beforeMount() {},
  methods: {},
};
</script>
