<template>
  <div>
    <div class="flex flex-wrap border border-blue-200">
      <div
        class="w-full sm:w-1/2 md:w-1/2 xl:w-1/3 border-b sm:border-b-0 sm:border-r border-blue-200 py-4 px-6"
      >
        <div class="mb-10">
          <sm-loader v-if="loading" />
          <div class="flex items-center" v-else>
            <div>
              <h1 class="text-2xl font-bold">
                {{ fullName }}
              </h1>
              <span
                class="text-gray-600 text-xs font-light"
              >
                Affiliate User
              </span>
            </div>

            <!-- <div class="dropdown ml-auto">
							<button type="button" class="flex flex-col focus:outline-none" dropdown>
								<div class="h-1 w-1 bg-gray-400 mb-1 rounded-full"></div>
								<div class="h-1 w-1 bg-gray-400 mb-1 rounded-full"></div>
								<div class="h-1 w-1 bg-gray-400 rounded-full"></div>
							</button>
							<ul class="dropdown-menu">
								<a href="#" class="dropdown-menu-item text-red-500">Delete</a>
							</ul>
						</div> -->
          </div>
        </div>

        <div class="mb-10">
          <div class="border-t border-blue-200 mb-5 pt-4">
            <div class="opacity-75 text-sm font-medium mb-1">
              Email Address
            </div>
            <sm-loader v-if="loading" />
            <div
              class="text-gray-500 text-sm font-light flex flex-row items-center"
              v-else
            >
              <ion-icon name="mail-outline" class="text-lg mr-2"></ion-icon>
              {{ staff.email }}
            </div>
          </div>
          <div class="border-t border-blue-200 mb-5 pt-4">
            <div class="opacity-75 text-sm font-medium mb-1">
              Phone Number
            </div>
            <sm-loader v-if="loading" />
            <div
              class="text-gray-500 text-sm font-light flex flex-row items-center"
              v-else
            >
              <ion-icon name="call-outline" class="text-lg mr-2"></ion-icon>
              {{ staff.phone }}
            </div>
          </div>
        </div>
      </div>

      <div
        class="w-full sm:w-1/2 md:w-1/2 xl:w-2/3 py-4 px-6"
        style="min-height: 600px"
      >
        <loader v-if="loading" />

        <template v-else>
          <tabs v-model="tab" :tabs="tabs" class="mb-6" />

          <component :is="tab" :staff="staff" />
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
	components: {
		profile: require('./Profile').default,
		referrals: require('./Referrals').default,
		affiliate_referrals: require('./ReferredAffiliates').default,
	},
	data() {
		return {
			approval: this.$options.basicForm(['credit_limit', 'loan_limit', 'interest_rate', 'billing_date', 'payment_date']),
			loading: false,
			staff: null,
			tab: 'profile',
			// isSuperAdmin: this.isSuperAdmin(),
			// isSuperRisk: this.isSuperRisk(),
			verifying: false,
			refuting: false,
			deactivating: false,
			profileStatus: 'pending',
			tabs: [
				{name: 'profile', title: 'Profile'},
				{name: 'referrals', title: 'Referrals'},
				{name: 'affiliate_referrals', title: 'Affiliate Referrals'},
			]
		}
	},
	computed: {
		position() {
			return this.staff?.profile ? this.staff?.profile.position : 'Staff';
		},
		userId() {
			return this.$route.params.userId;
		},
		hasStaffRole() {
			return this.staff?.roles?.find(role => role.slug == 'company_staff')
    },
    fullName() {
      return `${this.staff.first_name} ${this.staff.last_name}`
    }
	},
	beforeMount() {
		this.getUser();
	},
	methods: {
		reload(){
			location.reload()
		},
		async getUser() {
			this.loading = true;
			await this.$get({
				url: `${this.$baseurl}/admin/affiliates/${this.userId}`,
				headers: this.headers,
				success: (response) => {
					this.staff = {...response.data.data.data[0]};
				}
			});
			this.loading = false;
		},
	}
}
</script>
