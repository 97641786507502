<template>
	<div>

		<div class="flex flex-wrap border border-blue-200">
			<div class="w-full sm:w-1/2 md:w-1/3 xl:w-1/3 border-b sm:border-b-0 sm:border-r border-blue-200 py-4 px-6">

				<div class="mb-10">
					<sm-loader v-if="loading" />
					<div class="flex items-center" v-else>
						<div>
							<h1 class="text-2xl font-bold">
								{{ staff.name }}
							</h1>
							<span class="text-gray-600 text-xs font-light">
								{{ position | sentenceCase }} at {{ staff.company.name }}
							</span>
						</div>

						<div class="dropdown ml-auto">
							<button type="button" class="flex flex-col focus:outline-none" dropdown>
								<div class="h-1 w-1 bg-gray-400 mb-1 rounded-full"></div>
								<div class="h-1 w-1 bg-gray-400 mb-1 rounded-full"></div>
								<div class="h-1 w-1 bg-gray-400 rounded-full"></div>
							</button>
							<ul class="dropdown-menu">
								<a href="#!" class="dropdown-menu-item text-red-500" @click.prevent="deleteStaff">Delete</a>
							</ul>
						</div>

					</div>
				</div>

				<div class="mb-10">
					<div class="mb-5">
						<div class="opacity-75 text-sm font-medium mb-1">
							Profile Status
						</div>
						<sm-loader v-if="loading" />
						<div class="text-gray-500 text-sm font-light" v-else>
							<span
								class="text-orange-500 text-sm flex flex-row items-center"
								v-if="!staff.profile"
							>
								<ion-icon name="help-circle-outline" class="text-lg mr-2"></ion-icon>
								Not Updated
							</span>
							<span
								class="text-green-500 text-sm flex flex-row items-center"
								v-else-if="staff.profile.status === 'activated'"
							>
								<ion-icon name="checkmark-done-circle-outline" class="text-lg mr-2"></ion-icon>
								Activated
							</span>
							<span
								class="text-red-500 text-sm flex flex-row items-center"
								v-else-if="staff.profile.status === 'deactivated'"
							>
								<ion-icon name="warning-outline" class="text-lg mr-2"></ion-icon>
								Deactivated
							</span>
							<span
								class="text-orange-500 text-sm flex flex-row items-center"
								v-else
							>
								<ion-icon name="alert-circle-outline" class="text-lg mr-2"></ion-icon>
								Pending Approval
							</span>
						</div>
					</div>
					<div class="border-t border-blue-200 mb-5 pt-4">
						<div class="opacity-75 text-sm font-medium mb-1">
							Email Address
						</div>
						<sm-loader v-if="loading" />
						<div
							class="text-gray-500 text-sm font-light flex flex-row items-center"
							v-else
						>
							<ion-icon name="mail-outline" class="text-lg mr-2"></ion-icon>
							{{ staff.email }}
						</div>
					</div>
					<div class="border-t border-blue-200 mb-5 pt-4">
						<div class="opacity-75 text-sm font-medium mb-1">
							Phone Number
						</div>
						<sm-loader v-if="loading" />
						<div
							class="text-gray-500 text-sm font-light flex flex-row items-center"
							v-else
						>
							<ion-icon name="call-outline" class="text-lg mr-2"></ion-icon>
							{{ profileData('phone_no') }}
						</div>
					</div>
				</div>
				
			</div>

			<div class="w-full sm:w-1/2 md:w-2/3 xl:w-2/3 py-4 px-6" style="min-height: 600px">

				<loader v-if="loading" />

				<template v-else>

					<tabs v-model="tab" :tabs="tabs" class="mb-6" />

					<template v-if="tab == 'profile'">

						<div class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200">
							<div class="col-span-1 p-4">
								<div class="opacity-75 text-sm font-medium mb-1">Company</div>
								<div class="text-gray-500 text-sm font-light flex flex-row items-center">
									<ion-icon name="business-outline" class="text-lg mr-2"></ion-icon>
									{{ staff.company.name }}
								</div>
							</div>
							<div class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200">
								<div class="opacity-75 text-sm font-medium mb-1">Job Title</div>
								<div class="text-gray-500 text-sm font-light flex flex-row items-center">
									<ion-icon name="briefcase-outline" class="text-lg mr-2"></ion-icon>
									{{ profileData('position') }}
								</div>
							</div>
						</div>

						<div class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200" style="margin-top: -1px">
							<div class="col-span-1 p-4">
								<div class="opacity-75 text-sm font-medium mb-1">Employment Type</div>
								<div class="text-gray-500 text-sm font-light flex flex-row items-center">
									<ion-icon name="calendar-outline" class="text-lg mr-2"></ion-icon>
									{{ profileData('emp_type') }}
								</div>
							</div>
							<div class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200">
								<div class="opacity-75 text-sm font-medium mb-1">Salary</div>
								<div class="text-gray-500 text-sm font-light flex flex-row items-center">
									<ion-icon name="wallet-outline" class="text-lg mr-2"></ion-icon>
									{{ profileData('salary') | currency }}
								</div>
							</div>
						</div>

						<div class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200" style="margin-top: -1px">
							<div class="col-span-1 p-4">
								<div class="opacity-75 text-sm font-medium mb-1">Loan Facility</div>
								<div class="text-gray-500 text-sm font-light flex flex-row items-center">
									<ion-icon name="calculator-outline" class="text-lg mr-2"></ion-icon>
									{{ profileData('current_loan_facility') }}
								</div>
							</div>
							<div class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200">
								<div class="opacity-75 text-sm font-medium mb-1">Total Loan Amount</div>
								<div class="text-gray-500 text-sm font-light flex flex-row items-center">
									<ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
									{{ totalLoanAmount | currency }}
								</div>
							</div>
						</div>

						<div class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200" style="margin-top: -1px">
							<div class="col-span-1 p-4">
								<div class="text-sm font-medium mb-1 text-pink-500">Current Loan Type</div>
								<div class="text-pink-300 text-sm font-light flex flex-row items-center">
									<ion-icon name="document-text-outline" class="text-lg mr-2"></ion-icon>
									{{ currentLoanType }}
								</div>
							</div>
							<div class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200">
								<div class="text-sm font-medium mb-1 text-pink-500">Current Loan Amount</div>
								<div class="text-pink-300 text-sm font-light flex flex-row items-center">
									<ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
									{{ currentLoanAmount | currency }}
								</div>
							</div>
						</div>

					</template>

					<template v-else-if="tab === 'loans'">
						<datatable :data="loans" :columns="loanColumns" />
					</template>
					
				</template>

			</div>
			
		</div>

	</div>
</template>
<script>
	export default {
		data() {
			return {
				loading: false,
				loanColumns: [
					{
						name: 'loan_amount',
						th: 'Loan Amount',
						render: (loan, amount) => `₦ ${this.$options.filters.currency(amount)}`
					},
					{
						name: 'loan_type',
						th: 'Loan Type',
						render: (loan, type) => this.$options.filters.sentenceCase(type.replace('_', ' ')) + ' Loan'
					},
					{
						name: 'tenure',
						th: 'Loan Tenure',
						render: (loan, tenure) => `${tenure} Months`
					},
					{
						name: 'status',
						th: 'Status',
						render: (loan, status) => {
							switch (status) {
								case 'approved':
									return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-3 py-2 inline-block">
										Approved
									</div>`;
								case 'declined':
									return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-orange-100 text-xs px-3 py-2 inline-block">
										Pending
									</div>`;
								case 'paid':
									return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-3 py-2 inline-block">
										Paid
									</div>`;
								case 'pending':
								default:
									return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-orange-100 text-xs px-3 py-2 inline-block">
										Pending
									</div>`;
							}
						}
					},
					{
						name: 'created_at',
						th: 'Request Date',
						render: (loan, created_at) => this.$options.filters.dateFormat(created_at, 'D, dd M Y h:ia')
					}
				],
				staff: null,
				tab: 'profile',
				tabs: [
					{name: 'profile', title: 'Profile'},
					{name: 'loans', title: 'Loans'},
				]
			}
		},
		computed: {
			bankStatements() {
				return this.staff?.bank_statements || [];
			},
			currentLoan() {
				return this.staff?.active_loans?.[0];
			},
			currentLoanType() {
				const loanType = this.currentLoan?.loan_type;

				switch (loanType) {
					case 'standard':
						return 'Standard Loan';
					case 'pay_day':
						return 'Pay Day Loan';
					default:
						return this.currentLoan?.loan_type || 'None';
				}

			},
			currentLoanAmount() {
				return this.currentLoan?.loan_amount || 0;
			},
			lastBankStatement() {
				return this.bankStatements[this.bankStatements.length - 1];
			},
			position() {
				return this.staff?.profile ? this.staff?.profile.position : 'Staff';
			},
			staffId() {
				return this.$route.params.staffId;
			},
			totalLoanAmount() {
				return this.loans && this.loans.reduce( (last, transaction) => last + Number(transaction.loan_amount), 0 );
			},
			loans() {
				return this.staff?.loan || [];
			},
		},
		beforeMount() {
			this.getStaff();
		},
		methods: {
			profileData(key) {
				const data = this.staff?.profile?.[key];
				return data ? data : 'N/A';
			},
			async getStaff() {
				this.loading = true;
				await this.$get({
					url: `${this.$baseurl}/staff/${this.staffId}`,
					headers: this.headers,
					success: (response) => {
						this.staff = response.data.data;
					}
				});
				this.loading = false;
			},
			async deleteStaff() {
				this.loading = true;
				await this.$get({
					url: `${this.$baseurl}/staff/delete/${this.staffId}`,
					headers: this.headers,
					success: () => {
						this.staff = null;
					}
				});
				this.loading = false;
			},
		}
	}
</script>